import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { FormlyFieldConfig } from '@ngx-formly/core';

import { HashMap } from '@common/angular/interfaces';
import { ReferenceDataFacade } from '@ifhms/feedlot/front-end/shared/domain/state/reference-data';
import { EventProductItemComponent } from '@ifhms/feedlot/shared/domain/product-item';
import { FormlyTypesEnum, GRID_KEY, GridColumnDef, GridRendererCellType } from '@sersi/angular/formly/core';

import { UpcDosingGunsFacade } from '@ifhms/common/angular/upc/shared';
import { WorkOrderType } from '@ifhms/models/shared';

import { AbstractProductSelectorComponent } from '../abstract-product-selector.component';

@Component({
  selector: 'ifhms-event-product-selector',
  templateUrl: './event-product-selector.component.html',
  styleUrls: ['./event-product-selector.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EventProductSelectorComponent extends AbstractProductSelectorComponent {
  translateScope = 'product-selector';

  constructor(
    private referenceDataFacade: ReferenceDataFacade,
    private dosingGunsFacade: UpcDosingGunsFacade
  ) {
    super();
    this.dosingGunsFacade.getDosingGunsStatusFromUpc();
  }

  protected override onFieldInit(field: FormlyFieldConfig): void {
    super.onFieldInit(field);
    this.loadRefData();
  }

  protected getInitialModel(): HashMap {
    const initialModel: HashMap = {
      id: null,
      productTypeId: null,
      productId: null,
      routeId: null,
      recQty: null,
      qty: null,
      unit: null,
      routeDetailId: null,
      adminLocationId: this.fieldConfig.parent?.model?.defaultAdminLocationId || null,
      productLotNumber: null,
      productSerialNumber: null,
      expireDate: null,
      isCarryOver: false
    };
    if (this.fieldProps['hasFlatColumn']) {
      initialModel['flat'] = false;
    }
    return initialModel;
  }

  protected setGridField(): FormlyFieldConfig {
    return {
      className: 'event-product-selector grid-compact',
      type: FormlyTypesEnum.REPEATING_GRID_TYPE,
      key: GRID_KEY,
      props: {
        initialModel: this.getInitialModel(),
        gridOptions: this.getGridOptions(),
        noDataMessage$: this.getParentTranslation$('no-products-label')
      },
      expressions: {
        'props.disabled': () => !!this.fieldConfig?.props?.disabled
      },
      fieldArray: {
        fieldGroup: [
          this.setGridRow()
        ]
      }
    };
  }

  protected setGridRow(): FormlyFieldConfig {
    return {
      type: EventProductItemComponent,
      props: {
        gridOptions: this.getGridOptions(),
        contextKey: this.fieldKey,
        translateFn: (key: string) => this.getParentTranslation(key),
        translateFn$: (key: string) => this.getParentTranslation$(key),
        eventType: this.fieldProps['eventType']
      },
      expressions: {
        'props.disabled': () => !!this.fieldConfig?.props?.disabled,
        'props.isLocationOptional': () => !!this.fieldConfig?.parent?.model?.legacyId || !!this.fieldProps['isLocationOptional']
      }
    }
  }

  protected getColumnDefs(): GridColumnDef[] {
    const columnDefs: GridColumnDef[] = [
      {
        headerName$: this.getParentTranslation$('type-label'),
        fieldKey: 'productTypeId',
        width: '13%'
      },
      {
        headerName$: this.getParentTranslation$('product-label'),
        fieldKey: 'productId',
        width: '17%'
      },
      {
        headerName$: this.getParentTranslation$('route-label'),
        fieldKey: 'routeDetailId',
        width: '9%'
      },
      {
        headerName$: this.getParentTranslation$('recommended-qty-label'),
        fieldKey: 'recQty',
        width: '7%',
        minWidth: 45
      },
      {
        headerName$: this.getParentTranslation$('qty-label'),
        fieldKey: 'qty',
        width: '7%'
      },
      {
        headerName$: this.getParentTranslation$('unit-label'),
        fieldKey: 'unit',
        width: '7%'
      },
      {
        headerName$: this.getParentTranslation$('loc-label'),
        fieldKey: 'adminLocationId',
        width: '10%'
      },
      {
        headerName$: this.getParentTranslation$('flat-label'),
        fieldKey: 'flat',
        width: '30px',
        className: 'text-center',
        hideCell: !this.fieldProps['hasFlatColumn']
      },
      {
        fieldKey: 'info-btn',
        width: '24px',
        cellRendererParams: {
          type: GridRendererCellType.Info
        }
      }
    ]
    const dosingGunsEventsEnable = this.eventHasDosingGun();

    if (dosingGunsEventsEnable) {
      columnDefs.push({
        fieldKey: 'dosing-gun-btn',
        width: '24px',
        cellRendererParams: {
          type: GridRendererCellType.DosingGun
        }
      });
    }

    columnDefs.push(<GridColumnDef>{
      fieldKey: 'add-delete-btn',
      width: '24px',
      hotKey: 'ctrl+alt+a',
      cellRendererParams: {
        type: GridRendererCellType.ADD_DELETE
      }
    });

    return columnDefs;
  }

  private eventHasDosingGun(): boolean {
    return  this.fieldConfig.parent?.model?.workOrderType === WorkOrderType.Arrival
        || this.fieldConfig.parent?.model?.workOrderType === WorkOrderType.ReHandling;
  }

  private loadRefData(): void {
    const workEventDate = this.fieldConfig.parent?.model.workEventDate || new Date();
    const creationDate = workEventDate.toISOString();

    this.referenceDataFacade.getProductList(creationDate);
    this.referenceDataFacade.getProductRoutes(creationDate);
    this.referenceDataFacade.getProductTypes();
    this.referenceDataFacade.getInjectionLocations();
  }

}
