import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { WorkOrderType } from '@ifhms/models/shared';
import { Observable } from 'rxjs';
import { AH_FMS_FEEDLOT_DOMAIN_API } from '../../tokens';

@Injectable()
export class WorkOrderService {

  constructor(
    private http: HttpClient,
    @Inject(AH_FMS_FEEDLOT_DOMAIN_API) private readonly apiUrl: string
  ) {
  }

  createWorkOrder(feedlotId: string, workOrderType: WorkOrderType): Observable<string> {
    switch (workOrderType) {
      case WorkOrderType.Shipment:
        return this.createShipment(feedlotId);
      case WorkOrderType.Arrival:
        return this.createArrival(feedlotId);
      case WorkOrderType.Movement:
        return this.createMovement(feedlotId);
      case WorkOrderType.LotTransfer:
        return this.createLotTransfer(feedlotId);
      case WorkOrderType.ReHandling:
        return this.createRehandling(feedlotId);
      case WorkOrderType.Rail:
        return this.createRail(feedlotId);
    }
  }

  createShipment(feedlotId: string): Observable<string> {
    return this.http.post<string>(`${this.apiUrl}/Feedlot/${feedlotId}/Shipment/WorkOrder`, {});
  }

  createArrival(feedlotId: string): Observable<string> {
    return this.http.post<string>(`${this.apiUrl}/Feedlot/${feedlotId}/Arrival/WorkOrder`, {});
  }

  createMovement(feedlotId: string): Observable<string> {
    return this.http.post<string>(`${this.apiUrl}/Feedlot/${feedlotId}/Movement/WorkOrder`, {});
  }

  createLotTransfer(feedlotId: string): Observable<string> {
    return this.http.post<string>(`${this.apiUrl}/Feedlot/${feedlotId}/LotTransfer/WorkOrder`, {});
  }

  createRehandling(feedlotId: string): Observable<string> {
    return this.http.post<string>(`${this.apiUrl}/Feedlot/${feedlotId}/Rehandling/WorkOrder`, {});
  }

  createRail(feedlotId: string): Observable<string> {
    return this.http.post<string>(`${this.apiUrl}/Feedlot/${feedlotId}/Rail/WorkOrder`, {});
  }

  createRailPreSelect(feedlotId: string, animalIds: string[], allAnimalsSelected?: boolean, filters?: any): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}/Feedlot/${feedlotId}/Rail/RailAnimalsWorkOrder`,
      {
        ...filters,
        allAnimalSelected: allAnimalsSelected,
        animalIds: animalIds
      }
    );
  }
}
